const initVisiblePassword = () => {
  const { Theme } = window;
  const { visiblePassword: visiblePasswordMap } = Theme.selectors;
  const visiblePasswordList = document.querySelectorAll(visiblePasswordMap.visiblePassword);
  visiblePasswordList.forEach((input) => {
    const button = input == null ? void 0 : input.nextElementSibling;
    button == null ? void 0 : button.addEventListener("click", () => {
      const newType = input.getAttribute("type") === "text" ? "password" : "text";
      input.setAttribute("type", newType);
      button.setAttribute("aria-expanded", newType === "text" ? "true" : "false");
      const icon = button.firstElementChild;
      if (icon) {
        icon.innerHTML = newType === "text" ? "visibility_off" : "visibility";
        const { textHide, textShow } = button.dataset;
        if (textShow && textHide) {
          button.setAttribute("aria-label", newType === "text" ? textHide : textShow);
        }
      }
    });
  });
};
export default initVisiblePassword;
