import noUiSlider from "nouislider";
import wNumb from "wnumb";
import initFacets from "./update";
import filterHandler from "./filter-handler";
export const initSliders = () => {
  const { Theme } = window;
  document.querySelectorAll(Theme.selectors.facetedsearch.filterSlider).forEach((filter) => {
    const container = filter.querySelector(Theme.selectors.facetedsearch.rangeContainer);
    let unitPosition = "suffix";
    let unitSymbol = container.dataset.sliderUnit;
    let decimalCount = 2;
    let decimalSeparator = ".";
    let thousandsSeparator = "";
    const options = JSON.parse(container.dataset.sliderSpecifications);
    if (options !== null) {
      if (options.positivePattern !== void 0 && options.positivePattern.indexOf("\xA4") === 0) {
        unitPosition = "prefix";
      }
      if (options.currencySymbol !== void 0) {
        unitSymbol = options.currencySymbol;
      }
      if (options.numberSymbols !== void 0) {
        decimalSeparator = options.numberSymbols[0];
        thousandsSeparator = options.numberSymbols[1];
      }
      if (options.minFractionDigits !== void 0) {
        decimalCount = options.minFractionDigits;
      }
    }
    const min = parseInt(container.dataset.sliderMin, 10);
    const max = parseInt(container.dataset.sliderMax, 10);
    const sliderDirection = container.dataset.sliderDirection === "1" ? "rtl" : "ltr";
    let initiatedSlider;
    const tooltipsFormat = wNumb({
      mark: decimalSeparator,
      thousand: thousandsSeparator,
      decimals: decimalCount,
      [unitPosition]: unitPosition === "prefix" ? unitSymbol : ` ${unitSymbol}`
    });
    const sliderValues = JSON.parse(container.dataset.sliderValues);
    if (!container.noUiSlider) {
      const noUiBase = container.querySelector(".noUi-base");
      if (noUiBase) {
        noUiBase.remove();
      }
      initiatedSlider = noUiSlider.create(container, {
        start: sliderValues != null ? sliderValues : [min, max],
        tooltips: [tooltipsFormat, tooltipsFormat],
        direction: sliderDirection,
        connect: [false, true, false],
        range: {
          min,
          max
        }
      });
      initiatedSlider.on("set", (values, handle, unencoded, tap, positions, instance) => {
        filterHandler(values, instance);
      });
    } else {
      container.noUiSlider.updateOptions({
        start: sliderValues != null ? sliderValues : [min, max],
        tooltips: [tooltipsFormat, tooltipsFormat],
        range: {
          min,
          max
        }
      }, true);
      container.noUiSlider.on("set", (values, handle, unencoded, tap, positions, instance) => {
        filterHandler(values, instance);
      });
    }
  });
};
const toggleLoader = (toggle) => {
  const { Theme } = window;
  const loader = document.querySelector(Theme.selectors.pageLoader);
  if (loader) {
    loader.classList.toggle("d-none", toggle);
  }
};
document.addEventListener("DOMContentLoaded", () => {
  const { prestashop, Theme: { events } } = window;
  initFacets();
  prestashop.on(events.updateProductList, () => {
    toggleLoader(true);
    initSliders();
  });
  initSliders();
  prestashop.on(events.updateFacets, () => {
    toggleLoader(false);
  });
});
