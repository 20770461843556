import $ from "jquery";
window.$ = window.jQuery = $;
import "slick-carousel";
const SlickSlider = () => {
  $("[data-slick]").not(".slick-initialized").each(function() {
    let self = $(this);
    if (self.data("count") === 1) {
      return;
    }
    let params = {
      rows: 0
    };
    let dataSlick = self.attr("data-slick");
    if (dataSlick != "") {
      const settings = JSON.parse(dataSlick);
      if (typeof settings.vertical != "undefined" && (settings.vertical == "1" || settings.vertical == "yes")) {
        params.vertical = true;
      }
    }
    self.slick(params);
  });
};
export default SlickSlider;
