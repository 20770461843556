import themeSelectors from "./constants/selectors-map";
import EVENTS from "./constants/events-map";
import initEmitter from "./prestashop";
import initResponsiveToggler from "./responsive-toggler";
import initQuickview from "./quickview";
import initCart from "./pages/cart";
import initCheckout from "./pages/checkout";
import initCustomer from "./pages/customer";
import initProductBehavior from "./product";
import initMobileMenu from "./mobile-menu";
import initSearchbar from "./modules/ps_searchbar";
import initLanguageSelector from "./modules/ps_languageselector";
import initCurrencySelector from "./modules/ps_currencyselector";
import initVisiblePassword from "./visible-password";
import initErrorHandler from "./errors";
import useToast from "./components/useToast";
import useAlert from "./components/useAlert";
import usePasswordPolicy from "./components/usePasswordPolicy";
import useProgressRing from "./components/useProgressRing";
import useQuantityInput from "./components/useQuantityInput";
import "./modules/blockcart";
import "./modules/facetedsearch";
import initDesktopMenu from "./modules/ps_mainmenu";
import initFormValidation from "./form-validation";
import SlickSlider from "./components/slick";
require("waypoints/lib/jquery.waypoints");
initEmitter();
$(() => {
  const { prestashop, Theme: { events: events2 } } = window;
  const menuOffcanvas = document.getElementById("menuCanvas");
  menuOffcanvas.addEventListener("show.bs.offcanvas", (event) => {
    $("body").addClass("main-menu-opened");
  });
  menuOffcanvas.addEventListener("hide.bs.offcanvas", (event) => {
    $("body").removeClass("main-menu-opened");
  });
  var lastScrollTop = -1;
  $(window).scroll(function(event) {
    var st = $(this).scrollTop();
    if (lastScrollTop >= 150) {
      if (st > lastScrollTop) {
        $("body").addClass("scrolling-down");
        $("body").removeClass("scrolling-up");
      } else {
        $("body").addClass("scrolling-up");
        $("body").removeClass("scrolling-down");
      }
    }
    if (st > 150) {
      $("body").addClass("scrolling");
    } else {
      $("body").removeClass("scrolling");
      $("body").removeClass("scrolling-down");
      $("body").removeClass("scrolling-up");
    }
    lastScrollTop = st;
  });
  $("#main-nav li:has(> ul) > a").click(function() {
    $(this).parent("li").toggleClass("opened");
    return false;
  });
  $("#main-nav").on("click", ".back", function() {
    $(this).parents(".opened").removeClass("opened");
    return false;
  });
  SlickSlider();
  initProductBehavior();
  initQuickview();
  initCheckout();
  initCustomer();
  initResponsiveToggler();
  initCart();
  useQuantityInput();
  initSearchbar();
  initLanguageSelector();
  initCurrencySelector();
  initMobileMenu();
  initVisiblePassword();
  initDesktopMenu();
  initFormValidation();
  initErrorHandler();
  usePasswordPolicy(".field-password-policy");
  prestashop.on(events2.responsiveUpdate, () => {
    initSearchbar();
    initLanguageSelector();
    initCurrencySelector();
    initDesktopMenu();
  });
});
export const components = {
  useToast,
  useAlert,
  useProgressRing,
  useQuantityInput
};
export const selectors = themeSelectors;
export const events = EVENTS;
export default {
  initProductBehavior,
  initQuickview,
  initCheckout,
  initResponsiveToggler,
  initCart,
  useQuantityInput,
  initSearchbar,
  initLanguageSelector,
  initCurrencySelector,
  initMobileMenu,
  initVisiblePassword,
  initDesktopMenu
};
