import SlickSlider from "@js/components/slick";
export default () => {
  const { prestashop, Theme: { events } } = window;
  const initProductSlide = () => {
    SlickSlider();
  };
  $(window).resize();
  prestashop.on(events.updatedProduct, initProductSlide);
  prestashop.on(events.quickviewOpened, initProductSlide);
  prestashop.on(events.quickviewOpened, () => {
    $(".quickview .images__list").slick("setPosition");
  });
  $("#product-modal").on("shown.bs.modal", (event) => {
    $(".modal__product__images__list").slick("setPosition");
  });
};
